import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';

import { ReactComponent as HookImage } from "../../../assets/images/sidebar-hook.svg";
import Spinner from "../../../components/spinner/Spinner";
import { ENTER_KEY, isAgencyAdminRole, isAgencyRole, isClientRole } from "../../../shared/utility";
import LocalStorageService from "../../../store/LocalStorageServices";
import { getAgency } from "../../../store/selectors/agency";
import getRolesData from "../../../store/selectors/roles";
import { signout } from "../../../store/thunks/auth";
import backOffice from "../../../store/thunks/backOffice";

interface MyProfileSidebarProps {
  isActive: boolean,
}

const MyProfileSidebar: React.FC<MyProfileSidebarProps> = ({
  isActive
}) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const roles = useSelector((state) => getRolesData(state));
  const agency = useSelector((state) => getAgency(state));
  const [isLoading, setIsLoading] = useState(false);
  const loggedAccountID = LocalStorageService.getLoggedAccountId();

  const logout = () => {
    dispatch(signout());
  };

  const goToLink = (link: string) => {
    LocalStorageService.setEditMemberId(loggedAccountID as string);
    history.push(link);
  };

  const gotToBillingPortal = () => {
    if (agency.stripeId) {
      setIsLoading(true);
      dispatch<any>(backOffice(agency.stripeId))
        .then((response: any) => {
          setIsLoading(false);
          window.open(response);
        });
    }
  };

  return (
    <ul className={classNames('additional-filters', {
      'is-active': isActive,
    })}>
      { isAgencyRole(roles) || isClientRole(roles) ? (
        <>
          <li>
            <div
              className="sidebar-additional-items cursor-pointer"
              onClick={() => {goToLink('/agency-edit-member');}}
              onKeyUp={(event: any) => {
                if(event.key === 'Enter')
                  goToLink('/agency-edit-member');
              }}
              role="button"
              tabIndex={0}
            >
                <HookImage/>
              <span
                className={classNames('additional-filters__item', {
                  'is-active': history.location.pathname === '/agency-edit-member',
                })}
              >
                Personal Information
              </span>
            </div>
          </li>
          <li>
            <div
              className="sidebar-additional-items cursor-pointer"
              onClick={() => {goToLink('/my-preferences');}}
              onKeyUp={(event: any) => {
                if(event.key === 'Enter')
                  goToLink('/my-preferences');
              }}
              role="button"
              tabIndex={0}
            >
              <HookImage/>
              <span
                className={classNames('additional-filters__item', {
                  'is-active': history.location.pathname === '/my-preferences',
                })}
              >
                My Preferences
              </span>
            </div>
          </li>
        </>
      ) : null }
      {
        isAgencyAdminRole(roles) &&
        <li>
          <div className="sidebar-additional-items cursor-pointer">
            <HookImage/>
              <span
                className="additional-filters__item cursor-pointer"
                id="bill-info-btn"
                onClick={gotToBillingPortal}
                onKeyUp={(event: any) => {
                  if(event.key === ENTER_KEY)
                    gotToBillingPortal();
                }}
                role="button"
                tabIndex={0}
              >
              {isLoading ? <Spinner/> : 'Billing Information'}
            </span>
          </div>
        </li>
      }
      <li>
        <div className="sidebar-additional-items cursor-pointer">
          <HookImage/>
          <a
            className="additional-filters__item"
            href="auth"
            id="sign-out-btn"
            onClick={logout}
            tabIndex={0}
          >
            Sign Out
          </a>
        </div>
      </li>
    </ul>
  );
};

export default MyProfileSidebar;
