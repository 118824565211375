enum Tab {
  OPENED,
  COMPLETED,
  ASSIGNED_TO_ME,
  FOLLOWED_BY_ME,
  UNASSIGNED,
  ALL,
}

export interface FiltersState {
  selectedAdditionalFilter : Tab;
  selectedFilters: {[key: string]: any}
}

const initialState: FiltersState = {
  selectedAdditionalFilter: Tab.OPENED,
  selectedFilters: {},
};

const filterReducer = (state = initialState, action: {type: string, payload: any}): any => {

  const { type, payload } = action;

  let filters = state?.selectedFilters;

  if (type === 'UPDATE_FILTERS') {
    const {addedFilters, removedFilters } = payload;

    if (addedFilters.length > 0) {
      addedFilters.forEach((filter: { [key: string]: any; }) => {
        filters = {...filters, ...filter};
      });
    }

    if (removedFilters.length > 0) {
      removedFilters.forEach((filter: string | number) => {
        delete filters[filter];
      });
    }
  }

  switch (type) {
    case 'UPDATE_ADDITIONAL_FILTERS':
      return {
        ...state,
        selectedAdditionalFilter: payload };
    case 'RESET_ADDITIONAL_FILTER_LIST':
      return {
        ...state,
        selectedAdditionalFilter: Tab.OPENED
      };
    case 'UPDATE_FILTERS':
      return {
        ...state,
        selectedFilters: filters };
    case 'RESET_FILTER_LIST':
      return {
        ...state,
        selectedFilters: {}
      };
    default:
      return state;
  }
};

export default filterReducer;
