import './Header.scss';
import { useDispatch, useSelector } from 'react-redux';

import classNames from "classnames";

import { debounce } from "../../../shared/utility";
import { updateFilterList } from "../../../store/actions/filters";
import { isRequestDetailModalOpen } from "../../../store/selectors/modal";
import { getRequestsTotalItems } from "../../../store/selectors/requests";
import { isMainSidebarOpen } from "../../../store/selectors/sidebards";
import { AppDispatch } from "../../../store/store";
import { FilterValues, getTabIndexValue } from "../../Request/utility";
import { ChildComponentProps } from '../RoutingAPI/Routes';
import Logo from './Logo';
import MenuMobile from "./MenuMobile";
import SidebarMobileModal from "./SidebarMobileModal";
import SidebarNav from "./SidebarNav";

const Header: React.FC<ChildComponentProps> = ({
  items,
}: ChildComponentProps) => {

  const dispatch: AppDispatch = useDispatch();
  const isRequestOpen = useSelector((state) => isRequestDetailModalOpen(state));
  const isSidebarOpen = useSelector((state) => isMainSidebarOpen(state));
  const totalItems = useSelector((state) => getRequestsTotalItems(state));

  const handleSearch = (event: any) => {
    const search = event.target.value;
    if (search.length > 0)
      dispatch<any>(updateFilterList([{[FilterValues.SEARCH_ALL]: search}], []));
    else
      dispatch<any>(updateFilterList([{[FilterValues.SEARCH_ALL]: search}], [FilterValues.SEARCH_ALL]));
  };

  return (
    <header className="app--header region--header">
      <div className={classNames('header--primary hide-mobile', {
        shrink: isSidebarOpen,
        'sidebar--margin': !isSidebarOpen,
        hide: !window.location.pathname.includes(items[0].path),
      })}>
        <div className="region__inner--primary">
          <div className="header--total-items">
            <p className="header__title">Requests</p>
            <p className="p--big header__text requests__count">
              {totalItems} total
            </p>
          </div>
          <div className={classNames('form__item form__item--search')}>
            <input
              className="form--search form--search-open"
              id="search-request-input"
              name="search"
              onChange={debounce<typeof handleSearch>(handleSearch, 500)}
              placeholder="Search requests"
              type="search"
            />
          </div>
        </div>
      </div>
      <div className="header--primary hide-desktop">
       <div className="region__inner--primary">
         <Logo
           tabIndexValue={getTabIndexValue(isRequestOpen)}
         />
         <div className="header--total-items">
           <p className="header__title">Requests</p>
           <p className="p--big header__text requests__count">
             ({totalItems})
           </p>
         </div>
         <MenuMobile/>
       </div>
      </div>
      <div className={classNames('header--secondary', {
        collapsed: !isSidebarOpen
      })}>
        <div className="region__inner--secondary hide-mobile">
          <SidebarNav
            items={items}
          />
        </div>
      </div>
      <SidebarMobileModal items={items}/>
    </header>
  );
};

export default Header;
