export interface SidebarState {
  isSidebarOpen: boolean;
}

const initialState: SidebarState = {
  isSidebarOpen: true,
};

const sidebarReducer = (state = initialState, action: {type: string, payload: string}): any => {

  const { type, payload } = action;

  switch (type) {
    case 'TOGGLE_MAIN_SIDEBAR':
      return { isSidebarOpen: payload };
    default:
      return state;
  }
};

export default sidebarReducer;
