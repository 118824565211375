import { DEFAULT_VALUE_VIEW } from "../../containers/Request/utility";
import LocalStorageService from "../LocalStorageServices";

export interface ViewState {
  selectedView: string;
}

const initialState: ViewState = {
  selectedView: LocalStorageService.getDefaultView() || DEFAULT_VALUE_VIEW,
};

const viewReducer = (state = initialState, action: {type: string, payload: string}): any => {

  const { type, payload } = action;

  switch (type) {
    case 'UPDATE_VIEW':
      return { currentView: payload };
    default:
      return state;
  }
};

export default viewReducer;
