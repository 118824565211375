import { useReducer } from 'react';

// useToggle hook
const useToggle = (initialValue = false): any =>
  // Returns the tuple [state, dispatch]
  // Normally with useReducer you pass a value to dispatch to indicate what action to
  // take on the state, but in this case there's only one action.
  useReducer((state) => !state, initialValue);

export default useToggle;
