import {StatusFlag} from "../../services/model/Request";

export const getStatusFlags = (state: Record<string, any>): any =>
  state.statusFlagsReducer.data;

export const getNumberOfStatusFlags = (state: Record<string, any>): any =>
  state.statusFlagsReducer.data.length;

export const getActiveStatusFlags = (state: Record<string, any>): any =>
  state.statusFlagsReducer.data.filter((value: StatusFlag) => value.id !== 3);

export const getFinishedStatusFlag = (state: Record<string, any>): any =>
  state.statusFlagsReducer.data.find((value: StatusFlag) => value.id === 3);

export const getStatusFlagLoadingStatus = (state: Record<string, any>): any =>
  state.statusFlagsReducer.status;
