import {
  resetAdditionalFilterList,
  resetFilterList,
  updateAdditionalFilters,
  updateFilterList
} from "../actions/filters";
import { AppDispatch} from "../store";

// eslint-disable-next-line import/prefer-default-export
export const updateAdditionalFilter = (selectedAdditionalFilter: number) => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(updateAdditionalFilters(selectedAdditionalFilter));
};

export const updateAFilter = (addedFilters: {[key: string]: any}, removedFilters: string[]) => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(updateFilterList(addedFilters, removedFilters));
};

export const resetFilters = () => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(resetFilterList());
};

export const resetAdditionalFilters = () => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(resetAdditionalFilterList());
};
