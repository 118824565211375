import classNames from 'classnames';

import { ReactComponent as LogoImageMobile } from '../../../assets/images/logo-mobile.svg';
import { ReactComponent as LogoImage } from '../../../assets/images/logo.svg';

import './Logo.scss';

interface HeaderLogoProps {
  tabIndexValue?: number;
  collapsed?: boolean;
}

const Logo: React.FC<HeaderLogoProps> = ({
 tabIndexValue,
 collapsed,
}) => (
  <div className="logo">
    <a
      className="header-logo"
      href="request"
      tabIndex={tabIndexValue}
    >
      <div className="header-overlay-container">
        <div className={classNames('header-overlay-element', {
          active : collapsed,
        })}>
          <LogoImage/>
        </div>
        <div className={classNames('header-overlay-element', {
          active: !collapsed,
        })}>
            <LogoImageMobile/>
        </div>
      </div>
      <div className="hide-desktop">
        <LogoImageMobile/>
      </div>
    </a>
  </div>
);

export default Logo;
