import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import classNames from 'classnames';

import { ReactComponent as ClientImage } from '../../../assets/images/clients-icon.svg';
import { ReactComponent as ExpandSidebarIcon } from '../../../assets/images/expand-sidebar.svg';
import { ReactComponent as CloseSidebarIcon } from '../../../assets/images/minimize-sidebar.svg';
import { ReactComponent as SidebarRequestsArrow } from '../../../assets/images/requests-arrow.svg';
import { ReactComponent as RequestsImage } from '../../../assets/images/requests-icon.svg';
import { ReactComponent as SettingsImage } from '../../../assets/images/settings-icon.svg';
import { ReactComponent as TeamImage } from '../../../assets/images/team-icon.svg';
import Avatar from "../../../components/Avatar";
import useToggle from "../../../hooks/toggle";
import { toggleSidebar } from "../../../store/actions/sidebars";
import LocalStorageService from "../../../store/LocalStorageServices";
import { getAccountById } from "../../../store/selectors/accounts";
import { isMainSidebarOpen } from "../../../store/selectors/sidebards";
import AdditionalFilters from  '../../Request/Filter/AdditionalFilters';
import SettingSidebar from "../../Setting/SettingSidebar";
import { ChildComponentProps } from '../RoutingAPI/Routes';
import Logo from './Logo';
import MyProfileSidebar from "./MyProfileSidebar";

const SidebarNav: React.FC<ChildComponentProps> = ({ items }) => {
  const dispatch = useDispatch();

  const iconMapping = {
    Requests: <RequestsImage />,
    Team: <TeamImage />,
    Clients: <ClientImage />,
    Settings: <SettingsImage />,
  };

  const isSidebarOpen = useSelector((state) => isMainSidebarOpen(state));
  const [isCollapsed, collapse] = useToggle();
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [isMyProfileOpen, setIsMyProfileOpen] = useState<boolean>(false);
  const loggedAccountID = LocalStorageService.getLoggedAccountId();

  const loggedAccount = useSelector((state) =>
    getAccountById(state, loggedAccountID || '')
  );

  const handleCloseSidebar = () => {
    dispatch(toggleSidebar(false));
    if (isCollapsed)
      collapse();
  };

  const checkIsMyProfileActive = () =>
    window.location.pathname.includes('/my-preferences')
    || window.location.pathname.includes('/agency-edit-member')
    || isMyProfileOpen;

  const showSettingsDropdown = () =>
    isSettingsOpen && window.location.pathname.includes('/settings');

  const shouldShowAdditionalFilters = (itemTitle: string) =>
    itemTitle === 'Requests' && window.location.pathname.includes('/request') && isCollapsed;

  const navItems = items.filter((singleItem) => singleItem.path !== '/settings').map((item) => (
    <div className={classNames('sidebar--item', {
      'sidebar--item--active': window.location.pathname.replace('/app', '') === item.path,
      collapse: !isSidebarOpen,
      'sidebar--item--requests': item.title === 'Requests',
    })}
      key={item.title}>
      <div className="sidebar--requests">
        <NavLink
          activeClassName="is-active"
          className={classNames('sidebar--link', {
              collapsed: !isSidebarOpen,
              'sidebar--link--requests': item.title === 'Requests',
              'max-width': (isSidebarOpen && item.title !== 'Requests'),
          })}
          exact={item.isHomePage}
          id={`${item.path.replace('/', '')}-btn`}
          title={item.title}
          to={item.path}
        >
          <div className={classNames({
            'requests-icon-active':  item.title === 'Requests' && window.location.pathname.includes('/request'),
          })}>
            {iconMapping[item.title as keyof typeof iconMapping]}
          </div>
          <p className = {classNames('sidebar-item-title', {
            appear: isSidebarOpen,
          })}>
            {item.title}
          </p>
        </NavLink>
        <div
          className={classNames("requests--arrow--wrapper cursor-pointer", {
            'appear': item.title === "Requests" && isSidebarOpen ,
            'active': item.title === "Requests" && isSidebarOpen && window.location.pathname.includes('/request'),
            'in-active': item.title === "Requests" && isSidebarOpen && !window.location.pathname.includes('/request')
          })}
          onClick={() => window.location.pathname.includes('/request') && collapse()}
          onKeyUp={() => window.location.pathname.includes('/request') && collapse()}
          role="button"
          tabIndex={0}
        >
          <SidebarRequestsArrow
            className={classNames('requests--arrow', {
              open: !isCollapsed,
            })}
          />
        </div>
      </div>
      {shouldShowAdditionalFilters(item.title) &&
      <AdditionalFilters
        isClientRole={false}
      />}
    </div>
  ));

  return (
    <>
    <div className="nav__list">
      <div className="sidebar--logo">
        <Logo collapsed={isSidebarOpen} />
      </div>
      {navItems}
    </div>
    <div className="nav__list--secondary">
      {items.filter((singleItem) => singleItem.path === '/settings').map((item) => (
      <div className={classNames('sidebar--item', {
        'sidebar--item--active': window.location.pathname.includes(item.path),
        collapse: !isSidebarOpen,
        'sidebar--item--requests': item.title === 'Settings'
      })}
        key={item.title}>
        <div className="sidebar--requests">
        <NavLink
          activeClassName="is-active"
          className={classNames('sidebar--link', {
            collapsed: !isSidebarOpen,
          })}
          exact={item.isHomePage}
          id={`${item.path.replace('/', '')}-btn`}
          title={item.title}
          to={item.path}
        >
          <div>
            {iconMapping[item.title as keyof typeof iconMapping]}
          </div>
          <p className = {classNames('sidebar-item-title', {
            appear: isSidebarOpen,
          })}>
            {item.title}
          </p>
        </NavLink>
          <div
            className={classNames("requests--arrow--wrapper cursor-pointer" , {
              'appear': isSidebarOpen ,
              'active': isSidebarOpen && window.location.pathname.includes('/settings'),
              'in-active': isSidebarOpen && !window.location.pathname.includes('/settings')
            })}
            onClick={() => window.location.pathname.includes('/settings') && setIsSettingsOpen(!isSettingsOpen)}
            onKeyUp={() => window.location.pathname.includes('/settings') && setIsSettingsOpen(!isSettingsOpen) }
            role="button"
            tabIndex={0}
          >
            <SidebarRequestsArrow
              className={classNames('requests--arrow', {
                open: !isSettingsOpen,
              })}
            />
          </div>
        </div>
        {showSettingsDropdown() && <SettingSidebar/>}
      </div>))}
      <div className={classNames('sidebar--item sidebar--item--requests', {
        collapse: !isSidebarOpen,
        'sidebar--item--active': isMyProfileOpen,
      })}>
        <div className={classNames('sidebar-my-profile-container cursor-pointer', {
          active: checkIsMyProfileActive()
        })}
          onClick={() => setIsMyProfileOpen(!isMyProfileOpen)}
          onKeyUp={() => setIsMyProfileOpen(!isMyProfileOpen)}
          role="button"
          tabIndex={0}
        >
          <div className="user__avatar">
            <Avatar className="avatar--small" user={loggedAccount} />
          </div>
          <p className = {classNames('sidebar-item-title', {
            appear: isSidebarOpen,
          })}>
            My Profile
          </p>
          <div
            className={classNames("requests--arrow--wrapper cursor-pointer" , {
              'appear': isSidebarOpen ,
              'active': isSidebarOpen && checkIsMyProfileActive(),
              'in-active': isSidebarOpen && !checkIsMyProfileActive()
            })}
          >
            <SidebarRequestsArrow
              className={classNames('requests--arrow', {
                open: !isMyProfileOpen,
              })}
            />
          </div>
        </div>
        {isMyProfileOpen && <MyProfileSidebar isActive={isMyProfileOpen}/>}
      </div>
      <div className="cursor-pointer">
        {
          isSidebarOpen
            ? <CloseSidebarIcon onClick = {() => handleCloseSidebar()} />
            : <ExpandSidebarIcon onClick = {() => dispatch(toggleSidebar(true))} />
        }
      </div>
    </div>
    </>
  );
};

export default SidebarNav;
