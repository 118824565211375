import { UPDATE_ADDITIONAL_FILTERS, UPDATE_FILTERS } from "./actionTypes";

// eslint-disable-next-line import/prefer-default-export
export const updateAdditionalFilters = (selectedAdditionalFilter: number): any => ({
  type: UPDATE_ADDITIONAL_FILTERS,
  payload: selectedAdditionalFilter,
});

export const updateFilterList = (addedFilters: {[key: string]: any}, removedFilters: string[]): any => ({
  type: UPDATE_FILTERS,
  payload:{ addedFilters, removedFilters }
});

export const resetFilterList = (): any => ({
  type: 'RESET_FILTER_LIST'
});

export const resetAdditionalFilterList = (): any => ({
  type: 'RESET_ADDITIONAL_FILTERS'
});
