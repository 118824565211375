import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import { ReactComponent as HookImage } from '../../../assets/images/sidebar-hook.svg';
import { ENTER_KEY } from "../../../shared/utility";
import { updateAdditionalFilters, updateFilterList } from "../../../store/actions/filters";
import LocalStorageService from "../../../store/LocalStorageServices";
import { getCurrentAdditionalFilter } from "../../../store/selectors/filters";
import { getStatusFlags } from "../../../store/selectors/statusFlags";
import { getCurrentView } from "../../../store/selectors/views";
import { AppDispatch } from "../../../store/store";
import { resetAdditionalFilters } from "../../../store/thunks/filters";
import {
  AdditionalFilterProps,
  AdditionalFiltersProps,
  COMPLETED_TASKS_VALUE,
  FilterValues,
  NO_ASSIGNEE,
  openedRequests,
  View,
} from '../utility';

export enum Tab {
  OPENED,
  COMPLETED,
  ASSIGNED_TO_ME,
  FOLLOWED_BY_ME,
  UNASSIGNED,
  ALL,
}

const Filter: React.FC<AdditionalFilterProps> = ({
  additionalFilter,
  changeActive,
  title,
  tabValue,
  elementId,
  isActive,
  isRequestOpen,
}) => {
  const onChangeActive = (tabId: number | undefined) => {
    changeActive(tabId);
    additionalFilter();
  };

  return (
    <li>
      <div
        className="additional-item-container cursor-pointer"
        onClick={() => onChangeActive(tabValue)}
        onKeyUp={(event: any) => {
          if(event.key === ENTER_KEY)
            onChangeActive(tabValue);
        }}
        role="button"
        tabIndex={!isRequestOpen ? 0 : -1}
      >
        <HookImage/>
        <span
          className={classNames('additional-filters__item', {
            'is-active': isActive,
          })}
          id={elementId}
        >
        {title}
      </span>
      </div>
    </li>
  );
};

const AdditionalFilters: React.FC<AdditionalFiltersProps> = ({
  isClientRole,
  isRequestOpen,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const currentView = useSelector((state) => getCurrentView(state));
  const statusFlags = useSelector((state) => getStatusFlags(state));
  const loggedAccountID = LocalStorageService.getLoggedAccountId();
  const selectedAdditionalFilter = useSelector((state) => getCurrentAdditionalFilter(state));
  const [activeTab, setActiveTab] = useState<number[]>(currentView !== View.KANBAN ? [Tab.OPENED] : [Tab.ALL]);

  const defaultStatuses = useMemo( () =>
      openedRequests(statusFlags, currentView === View.KANBAN)
    ,[currentView, statusFlags]);

  useEffect(() => {
    if (selectedAdditionalFilter !== undefined) {
      setActiveTab([selectedAdditionalFilter]);
    }
  }, [selectedAdditionalFilter]);

  useEffect(() => {
    setActiveTab(currentView !== View.KANBAN ? [Tab.OPENED] : [Tab.ALL]);
  }, [currentView]);

  useEffect(() => {
    dispatch<any>(resetAdditionalFilters());
  },[dispatch]);

  const changeActive = (tabId: number | undefined) => {
    if (tabId !== undefined) {
      const activeTabs = [tabId];
      setActiveTab(activeTabs);
    }
  };

  const clearAssigneeFilter = () => {
    dispatch<any>(updateAdditionalFilters(Tab.OPENED));
    dispatch<any>(updateFilterList(
      [{}],
      [FilterValues.ASSIGNEE, FilterValues.WATCHERS, FilterValues.NO_ASSIGNEE, FilterValues.COMPLETED])
    );
  };
  const showCompleted = () => {
    dispatch<any>(updateAdditionalFilters(Tab.COMPLETED));
    dispatch<any>(updateFilterList(
      [{[FilterValues.COMPLETED]: COMPLETED_TASKS_VALUE}],
      [FilterValues.ASSIGNEE, FilterValues.WATCHERS, FilterValues.NO_ASSIGNEE, FilterValues.STATUS_FLAG]));
  };

  const showAllFilter = () => {
    dispatch<any>(updateAdditionalFilters(Tab.ALL));
    dispatch<any>(updateFilterList(
      [{[FilterValues.STATUS_FLAG]: defaultStatuses}],
      [FilterValues.ASSIGNEE, FilterValues.WATCHERS, FilterValues.NO_ASSIGNEE, FilterValues.COMPLETED]));
  };

  const assignedToUserFilter = (filter?: string) => {

    if (filter !== undefined) {
      dispatch<any>(updateAdditionalFilters(Tab.UNASSIGNED));
      dispatch<any>(updateFilterList(
        [{[FilterValues.NO_ASSIGNEE]: false}],
        [FilterValues.COMPLETED, FilterValues.ASSIGNEE, FilterValues.WATCHERS]));
    } else {
      dispatch<any>(updateAdditionalFilters(Tab.ASSIGNED_TO_ME));
      dispatch<any>(updateFilterList(
        [{[FilterValues.ASSIGNEE]: loggedAccountID}],
        [FilterValues.COMPLETED, FilterValues.NO_ASSIGNEE, FilterValues.WATCHERS]));
    }
  };
  const followByUserFilter = () => {
    dispatch<any>(updateAdditionalFilters(Tab.FOLLOWED_BY_ME));
    dispatch<any>(updateFilterList(
      [{[FilterValues.WATCHERS]: loggedAccountID}],
      [FilterValues.COMPLETED, FilterValues.ASSIGNEE, FilterValues.NO_ASSIGNEE]));
  };
  return (
    <ul className="additional-filters">
      {currentView !== View.KANBAN &&
          <Filter
            additionalFilter={() => clearAssigneeFilter()}
            changeActive={changeActive}
            elementId="open-filter"
            isActive={activeTab[0] === Tab.OPENED}
            isRequestOpen={isRequestOpen}
            tabValue={Tab.OPENED}
            title="Open"
          />
      }
      {currentView === View.KANBAN &&
      <Filter
        additionalFilter={() => showAllFilter()}
        changeActive={changeActive}
        elementId="all-filter"
        isActive={activeTab[0] === Tab.ALL}
        isRequestOpen={isRequestOpen}
        tabValue={Tab.ALL}
        title="All"
      />
      }
      {!isClientRole &&
        <Filter
          additionalFilter={() => assignedToUserFilter()}
          changeActive={changeActive}
          elementId="assigned-to-me-filter"
          isActive={activeTab[0] === Tab.ASSIGNED_TO_ME}
          isRequestOpen={isRequestOpen}
          tabValue={Tab.ASSIGNED_TO_ME}
          title="Assigned To Me"
        />
      }
      <Filter
        additionalFilter={() => followByUserFilter()}
        changeActive={changeActive}
        elementId="following-filter"
        isActive={activeTab[0] === Tab.FOLLOWED_BY_ME}
        isRequestOpen={isRequestOpen}
        tabValue={Tab.FOLLOWED_BY_ME}
        title="Following"
      />
      {!isClientRole &&
        <Filter
          additionalFilter={() => assignedToUserFilter(NO_ASSIGNEE)}
          changeActive={changeActive}
          elementId="unassigned-filter"
          isActive={activeTab[0] === Tab.UNASSIGNED}
          isRequestOpen={isRequestOpen}
          tabValue={Tab.UNASSIGNED}
          title="Unassigned"
        />
      }
      {currentView !== View.KANBAN &&
        <Filter
          additionalFilter={() => showCompleted()}
          changeActive={changeActive}
          elementId="finished-filter"
          isActive={activeTab[0] === Tab.COMPLETED}
          isRequestOpen={isRequestOpen}
          tabValue={Tab.COMPLETED}
          title='Finished'
        />
      }
    </ul>
  );
};

export default AdditionalFilters;
