import { createSelector } from 'reselect';

import { Request } from '../../services/model/Request';
import { Status } from '../utillity';

export const getRequestsData = (state: Record<string, any>): any => state.requestsReducer.data;

export const getRequestCreationStatus = (state: Record<string, any>): string =>
  state.requestsReducer.creationStatus;

export const getRequestsTotalItems = (state: Record<string, any>): number =>
  state.requestsReducer.totalItems;

export const getCurrentPage = (state: Record<string, any>): number =>
  state.requestsReducer.currentPage;

export const getSelectedRequests = (state: Record<string, any>): any =>
  state.requestCheckedListReducer.data;

export const getSelectedRequestsForStatus = (state: Record<string, any>, status: string): any =>
  state.requestCheckedListReducer.data.filter((request: Request) => request.statusFlagId.name === status);

export const getSelectedRequestsExceptForStatus = (state: Record<string, any>, status: string): any =>
  state.requestCheckedListReducer.data.filter((request: Request) => request.statusFlagId.name !== status);

export const getSelectedRequest = (state: Record<string, any>): any =>
  state.requestsReducer.selectedReq;

export const isSelected = createSelector(
  getSelectedRequests,
  (_: any, id: number) => id, // this is the parameter we need
  (requests, id) => requests.map((item: Request) => item.id).includes(id)
);

export const getRequestComments = createSelector(
  (state: Record<string, any>) => state.requestsReducer.data,
  (_: any, id: number) => id, // this is the parameter we need
  (requests, id) => {
    const req = requests.find((request: Request) => request.id === id);
    return req?.comments;
  }
);

export const getRequestById = createSelector(
  (state: Record<string, any>) => state.requestsReducer.data,
  (_: any, id: number) => id, // this is the parameter we need
  (requests, id) => requests.find((request: Request) => request.id === id)
);

export const getReqStatus = (state: Record<string, any>): Status => state.requestsReducer.status;
