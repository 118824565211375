import {
  toggle,
  toggleClientModal,
  toggleCreateLabel,
  toggleDeleteRequestType,
  toggleInvitation,
  toggleMobileSidebarModal,
  toggleNavigation,
  togglePreviewImageModal,
  toggleRequestDetail,
  toggleRequestFormPreview,
  toggleSubmitRequest,
  toggleSubmitRequestConfirmationModal,
  toggleTimeLogging,
  toggleToastMessageModal,
} from '../actions/modal';
import { AppDispatch } from '../store';

export const toggleModal = (isOpen: boolean) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(toggle(isOpen));
};

export const toggleClientConfirmation = (isClientOpen: boolean) => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(toggleClientModal(isClientOpen));
};

export const toggleInvitationModal = (isInvitationOpen: boolean) => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(toggleInvitation(isInvitationOpen));
};

export const toggleNav = (isNavOpen: boolean) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(toggleNavigation(isNavOpen));
};

export const toggleRequestDetailModal = (
  isRequestDetailOpen: boolean
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(toggleRequestDetail(isRequestDetailOpen));
};

export const toggleTimeLoggingModal = (isTimeLoggingOpen: boolean) => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(toggleTimeLogging(isTimeLoggingOpen));
};

export const toggleDeleteRequestTypeModal = (
  isDeleteRequestTypeOpen: boolean
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(toggleDeleteRequestType(isDeleteRequestTypeOpen));
};

export const toggleSubmitRequestModal = (
  isSubmitRequestOpen: boolean
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(toggleSubmitRequest(isSubmitRequestOpen));
};

export const toggleRequestFormPreviewModal = (
  isRequestFormPreviewOpen: boolean
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(toggleRequestFormPreview(isRequestFormPreviewOpen));
};

export const toggleCreateLabelModal = (isCreateLabelOpen: boolean) => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(toggleCreateLabel(isCreateLabelOpen));
};

export const toggleAddLabelModal = (isAddLabelOpen: boolean) => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(toggleCreateLabel(isAddLabelOpen));
};

export const toggleRequestConfirmation = (isSubmitRequestConfirmationOpen: boolean) => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(toggleSubmitRequestConfirmationModal(isSubmitRequestConfirmationOpen));
};

export const toggleImagePreviewModal = (isImagePreviewOpen: boolean) => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(togglePreviewImageModal(isImagePreviewOpen));
};
export const toggleToastMessage = (isToastMessageOpen: boolean) => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(toggleToastMessageModal(isToastMessageOpen));
};
export const toggleMobileSidebar = (isMobileSidebarOpen: boolean) => async (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch(toggleMobileSidebarModal(isMobileSidebarOpen));
};
