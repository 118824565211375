import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import classNames from "classnames";

import { ReactComponent as ClientImage } from "../../../assets/images/clients-icon.svg";
import { ReactComponent as SidebarRequestsArrow } from "../../../assets/images/requests-arrow.svg";
import { ReactComponent as RequestsImage } from "../../../assets/images/requests-icon.svg";
import { ReactComponent as SettingsImage } from "../../../assets/images/settings-icon.svg";
import { ReactComponent as TeamImage } from "../../../assets/images/team-icon.svg";
import useToggle from "../../../hooks/toggle";
import { toggleMobileSidebarModal } from "../../../store/actions/modal";
import { isMobileSidebarOpen } from "../../../store/selectors/modal";
import { AppDispatch } from "../../../store/store";
import AdditionalFilters from "../../Request/Filter/AdditionalFilters";
import { NavInfo } from "../RoutingAPI/Routes";
import UserAvatar from "./UserAvatar";

interface SidebarMobileModalProps {
  items: NavInfo[];
}

const iconMapping = {
  Requests: <RequestsImage/>,
  Team: <TeamImage/>,
  Clients: <ClientImage/>,
  Settings: <SettingsImage/>,
};

const SidebarMobileModal: React.FC<SidebarMobileModalProps> = ({
  items,
  }) => {
  const dispatch: AppDispatch = useDispatch();
  const sidebarModalRef = useRef<HTMLDivElement | null>(null);
  const isSidebarModalOpen = useSelector((state) => isMobileSidebarOpen(state));
  const [isCollapsed, collapse] = useToggle();

  const shouldShowAdditionalFilters = (itemTitle: string) =>
    itemTitle === 'Requests' && window.location.pathname.includes('/request') && isCollapsed;

  const clickOutside = useCallback((e: any) => {
    if(!(sidebarModalRef.current as any)?.contains(e.target)) {
      dispatch<any>(toggleMobileSidebarModal(false));
    }
  }, [dispatch]);

  useEffect(() => {
    if(isSidebarModalOpen) {
      document.addEventListener("click", clickOutside);
    }

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, [clickOutside, isSidebarModalOpen]);

  const navItems = items.filter((singleItem) => singleItem.path !== '/settings').map((item) => (
    <div className={classNames('sidebar--item', {
      'sidebar--item--active': window.location.pathname.replace('/app', '') === item.path,
      'sidebar--item--requests': item.title === 'Requests',
    })}
      key={item.title}>
      <div className="sidebar--requests">
        <NavLink
          activeClassName="is-active"
          className={classNames('sidebar--link', {
            'max-width': (item.title !== 'Requests'),
          })}
          exact={item.isHomePage}
          onClick={() => dispatch<any>(toggleMobileSidebarModal(false))}
          to={item.path}
        >
          <div>
            {iconMapping[item.title as keyof typeof iconMapping]}
          </div>
          <p>
            {item.title}
          </p>
        </NavLink>
        <SidebarRequestsArrow
          className={classNames('requests--arrow cursor-pointer', {
            open: !isCollapsed,
            hide: item.title !== 'Requests',
          })}
          onClick={collapse}
        />
      </div>
      {shouldShowAdditionalFilters(item.title) &&
      <AdditionalFilters
        isClientRole={false}
      />}
    </div>
  ));

  return (
    <div
      className={classNames('modal modal--center modal--secondary modal--sidebar--mobile', {
      open: isSidebarModalOpen
      }
      )}
      ref={sidebarModalRef}>
      <div className="region__inner--secondary hide-desktop">
        <div className="nav__list">
          {navItems}
        </div>
        <div className="nav__list--secondary">
          {items.filter((singleItem) => singleItem.path === '/settings').map((item) => (
            <div className={classNames('sidebar--item', {
              'sidebar--item--active': window.location.pathname.includes(item.path)
            })}
              key={item.title}>
              <NavLink
                activeClassName="is-active"
                className={classNames('sidebar--link'
                )}
                exact={item.isHomePage}
                onClick={() => dispatch<any>(toggleMobileSidebarModal(false))}
                to={item.path}
              >
                <div>
                  {iconMapping[item.title as keyof typeof iconMapping]}
                </div>
                <p>
                  {item.title}
                </p>
              </NavLink>
            </div>))}
          <div className={classNames('sidebar--item')}>
            <UserAvatar
              collapsed
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobileModal;
