import CannedResponse from '../../services/model/CannedResponse';
import { RequestType } from '../../services/model/RequestType';
import { NavInfo } from '../PageLayout/RoutingAPI/Routes';

export interface RequestTypeRemoveModalProps {
  requestType: RequestType;
  shouldRedirect?: boolean;
}
export interface SettingsSidebarProps {
  setPage: (page: SettingPage) => void;
}
export enum SettingPage {
  INPUT_FORM = 'INPUT_FORM',
  ADD_INPUT_FORM = 'ADD_INPUT_FORM',
  CANNED_RESPONSE = 'CANNED_RESPONSE',
  ADD_CANNED_RESPONSE = 'ADD_CANNED_RESPONSE',
  EDIT_CANNED_RESPONSE = 'EDIT_CANNED_RESPONSE',
}
export interface CannedResponseRemoveModalProps {
  isOpen: boolean;
  cannedResponse: CannedResponse;
  setIsModalOpen: (isOpen: boolean) => void;
}
export const menuItems: NavInfo[] = [
  {
    path: '/settings/input-forms',
    title: 'Input Forms',
    isHomePage: true,
  },
  {
    path: '/settings/status-flags',
    title: 'Status Flags',
    isHomePage: false,
  },
  {
    path: '/settings/canned-responses',
    title: 'Canned Responses',
    isHomePage: false,
  },
  {
    path: '/settings/agency',
    title: 'Company Settings',
    isHomePage: false,
  },
  // {
  //   path: '/settings/content-page',
  //   title: 'Content Page',
  //   isHomePage: false,
  // },
  // {
  //   path: '/settings/billing',
  //   title: 'Billing',
  //   isHomePage: false,
  // },
];
