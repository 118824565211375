import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as HookImage } from "../../assets/images/sidebar-hook.svg";
import { menuItems } from './utility';

const SettingSidebar: React.FC = () => {
  const {pathname} = useLocation();

  const isPageMatching = (item: any) => {
    const lastItem = item.path.split("/").pop();
    return pathname.includes(lastItem.slice(0,-1));
  };

  return (
        <ul className="additional-filters">
          {menuItems.map((item) => (
            <li className="" key={item.title}>
              <div className="sidebar-additional-items cursor-pointer">
                <HookImage/>
                <NavLink
                  activeClassName="is-active"
                  className="additional-filters__item"
                  isActive={()=> isPageMatching(item)}
                  to={item.path}
                >
                  {item.title}
                </NavLink>
              </div>
            </li>
          ))}
        </ul>
  );
};
export default SettingSidebar;
