import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as MenuIcon } from '../../../assets/images/menu-mobile.svg';
import { toggleMobileSidebarModal } from "../../../store/actions/modal";
import { isMobileSidebarOpen } from "../../../store/selectors/modal";
import { AppDispatch } from "../../../store/store";

interface MenuMobileProps {
  tabIndexValue?: number;
}

const Logo: React.FC<MenuMobileProps> = ({
tabIndexValue
}) => {
 const dispatch: AppDispatch = useDispatch();
 const isSidebarModalOpen = useSelector((state) => isMobileSidebarOpen(state));

  return (
  <div
    className="logo"
    onClick={() => dispatch<any>(toggleMobileSidebarModal(!isSidebarModalOpen))}
    onKeyUp={() => dispatch<any>(toggleMobileSidebarModal(!isSidebarModalOpen))}
    role="button"
    tabIndex={tabIndexValue}
  >
        <MenuIcon/>
  </div>
);};

export default Logo;
